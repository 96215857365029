<template>
	<SubPageListLayout :sub_bar="!builderStore.activeTemplate.workflow_id" :loading="builderStore.loading">
		<template #sub_bar>
			<div class="px-6 pt-3 pb-5">
				<h2 class="text-lg font-medium text-gray-900">Search workflows</h2>
				<form class="mt-2 flex space-x-4" action="#">
					<div class="min-w-0 flex-1">
						<label for="search" class="sr-only">Search</label>
						<div class="relative rounded-md shadow-sm">
							<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
								<FontAwesomeIcon :icon="faMagnifyingGlass" aria-hidden="true" class="h-5 w-5 text-gray-400" />
							</div>
							<input id="search" type="search" name="search" class="block w-full rounded-md border-gray-300 pl-10 focus:border-pink-500 focus:ring-pink-500 sm:text-sm" placeholder="Search" />
						</div>
					</div>
				</form>
			</div>
			<hr />
			<div class="min-h-0 flex-1 overflow-y-auto">
				<div
					v-for="workflow in workflowStore.workflows"
					:key="workflow.id"
					:class="[builderStore.activeTemplate.workflow_id === workflow.id ? 'bg-primary-50 bg-opacity-50 shadow-inner' : 'hover:bg-primary-50', 'first:pt-4 flex flex-col pt-3 px-3 pb-1 border-b border-blue-gray-200 cursor-pointer']"
					:aria-current="builderStore.activeTemplate.workflow_id === workflow.id ? 'page' : undefined"
					@click="() => selectTemplateWorkflow(workflow.id)"
				>
					<div class="flex flex-row w-full pointer-events-none">
						<FontAwesomeIcon :icon="faRectangleList" aria-hidden="true" class="mt-0.5 h-6 w-6 flex-shrink-0 text-primary" />
						<div class="ml-3 w-full flex flex-col relative">
							<p class="text-base font-bold text-black">{{ workflow.name }}</p>
							<p class="text-sm my-1 text-gray-900 italic">{{ workflow.description }}</p>
							<div class="flex flex-row w-full justify-between">
								<p class="text-xxs font-light text-gray-900">Created by {{ workflow.creator.first_name }} {{ workflow.creator.last_name }} {{ getRelativeFormatFrom(getLocalizedDate(workflow.created_at)) }}</p>
								<p class="text-xxs font-light text-gray-900">Updated {{ getRelativeFormatFrom(getLocalizedDate(workflow.updated_at)) }}</p>
							</div>
							<div class="absolute right-0 top-0">
								<span v-if="workflow.status_id == WorkflowStatus.DRAFT" class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">{{ WorkflowStatusLabel[workflow.status_id] }}</span>
								<span v-else-if="workflow.status_id == WorkflowStatus.PUBLISHED" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{{ WorkflowStatusLabel[workflow.status_id] }}</span>
								<span v-else-if="workflow.status_id == WorkflowStatus.ARCHIVED" class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">{{ WorkflowStatusLabel[workflow.status_id] }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #sub_content>
			<div class="flex flex-col h-full w-full">
				<div
					class="h-12 bg-white border-b shadow-md flex flex-row items-center justify-between px-8 transition-all duration-500 transform"
					:class="{
						'-mt-12': !builderStore.activeTemplate.workflow_id,
					}"
				>
					<div class="w-64">
						<button
							class="flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-danger-500 hover:bg-gray-100 focus:outline-none"
							type="button"
							@click="resetSelectedTemplateWorkflow"
						>
							Change
							<FontAwesomeIcon :icon="faPenToSquare" class="h-5 w-5 text-white ml-2" aria-hidden="true" />
						</button>
					</div>
					<div class="font-bold text-lg">{{ builderStore.activeTemplate.workflow_id ? workflowStore.workflow.name : '' }}</div>
					<div class="w-64 text-right"></div>
				</div>
				<div v-if="!builderStore.activeTemplate.workflow_id" class="flex flex-col h-full w-full justify-center items-center">
					<div class="rounded-lg border-dashed border-2 h-72 flex flex-col justify-center items-center w-3/4 border-black">
						<FontAwesomeIcon :icon="faClipboardListCheck" aria-hidden="true" class="h-24 w-24" />
						<h1 class="select-none mt-6 font-medium text-2xl">To start you need to select a workflow on the left! (You create these in the workflow section)</h1>
					</div>
				</div>
				<div v-if="builderStore.activeTemplate.workflow_id" class="flex flex-row w-full h-full justify-around bg-gray-50">
					<div class="flex flex-col h-full w-full py-8 mx-8">
						<WorkflowBuilder :allow-edit="false" :function-mode="true" :workflow-id="builderStore.activeTemplate.workflow_id" />
					</div>
				</div>
			</div>
		</template>
	</SubPageListLayout>
</template>

<script lang="ts" setup>
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faClipboardListCheck, faMagnifyingGlass, faPenToSquare, faRectangleList } from '@fortawesome/pro-light-svg-icons';
	import SubPageListLayout from '@layouts/SubPageListLayout.vue';
	import { watch } from 'vue';
	import { useBuilderStore } from '@modules/builder/store';
	import { getLocalizedDate, getRelativeFormatFrom } from '@utils/useDate';
	import { useWorkflowStore } from '@modules/workflow/store';
	import WorkflowBuilder from '@modules/workflow/components/WorkflowBuilder.vue';
	import { WorkflowStatus, WorkflowStatusLabel } from '@/types/workflow';

	const workflowStore = useWorkflowStore();
	const builderStore = useBuilderStore();

	const selectTemplateWorkflow = (workflowId: string) => {
		builderStore.template.workflow_id = workflowId;
		builderStore.updateTemplate();
	};

	const resetSelectedTemplateWorkflow = () => {
		builderStore.template.workflow_id = undefined;
	};

	watch(
		() => builderStore.template.workflow_id,
		(newValue) => {
			if (!newValue) {
				workflowStore.getWorkflows();
			}
		},
		{
			immediate: true,
		},
	);
</script>

<style scoped></style>
